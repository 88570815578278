import { Component } from '@angular/core';

@Component({
  selector: 'app-wrongurl',
  templateUrl: './wrongurl.component.html',
  styleUrls: ['./wrongurl.component.css']
})
export class WrongurlComponent {

}
