import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { debounce, debounceTime, distinctUntilChanged } from 'rxjs';
import { Cart } from 'src/app/models/cart.model';
import { Product } from 'src/app/models/product.model';
import { AuthService } from 'src/app/services/auth.service';
import { LoggerService } from 'src/app/services/logger.service';
import { ProductService } from 'src/app/services/product.service';

const routerLinks = [
  { name: 'Home', link: '/' },
  { name: 'Products', link: '/products' },
  { name: 'About', link: '/about' }
];

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  links: any[] = routerLinks;
  constructor(
    private router: Router,
    private loggerService: LoggerService,
    private authService: AuthService,
    private productService:ProductService
  ) {}
  loggedIn: boolean = false;
  count: number = 0;
  open: boolean = false;
  searchResult: any | undefined;

  ngOnInit() {
  }

  logout() {
    this.count = 0;
    this.authService.logout();
  }

  show() {
    this.open = true;
  }
  hide() {
    this.open = false;
  }

  searchProduct(query: KeyboardEvent) {
    if (query) {
      const element = query.target as HTMLInputElement;
       if(element.value.length){
         this.productService
           .getSearchProducts(element.value)
           .pipe(debounceTime(1000), distinctUntilChanged())
           .subscribe((res) => {
             this.searchResult = res;
           });
       }
    }
  }

  search(query:String){
    if(query.length){
      this.router.navigate([`/products/search/${query}`]);
    }
  }
  hideSearch() {
    this.searchResult = undefined;
  }
  redirectToDetails(id: string) {
    this.router.navigate(['/products/' + id]);
  }
}
