<!-- <div *ngIf="loading " class="loader w-100 h-100 d-flex justify-content-center position-absolute  ">
    <div class="spinner-border mx-auto" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div> -->rr

<!--<div *ngIf="notFound && !orderDetail " class="p-4 d-flex flex-column align-items-center text-center">-->
<!--    <span>Order is successfully canceled</span>-->
<!--    <button class="btn btn-primary" routerLink="/order">Go back</button>-->
<!--</div>-->

<!--<div *ngIf="orderDetail" class="py-2 d-flex flex-md-row flex-column justify-content-around align-items-center align-items-sm-start ">-->
<!--    <div *ngIf="orderDetail.orderDetails" class="box">-->
<!--        <div class="p-2 d-flex flex-column gap-4 flex-md-row justify-content-between align-items-start">-->
<!--            <h6>Order id : {{orderDetail._id}}</h6>-->
<!--       <div class="d-flex flex-column gap-2">-->
<!--           <span>Status :-->
<!--               <span class="badge p-2" [class.bg-warning]="orderDetail.status ==='completed'"-->
<!--               [class.bg-success]="orderDetail.status ==='delivered'" [class.bg-danger]="orderDetail.status ==='canceled'">-->
<!--               {{orderDetail.status | titlecase}}-->
<!--            </span>-->
<!--        </span>-->
<!--        <span style="font-size: 14px;">Order on: &nbsp;{{orderDetail.createdAt | date:'medium'}}</span>-->
<!--       </div>-->
<!--        </div>-->
<!--        <div *ngFor="let item of orderDetail.orderDetails.products">-->
<!--            <div *ngIf="item.canceled===false || item.canceled === true && orderDetail.status==='canceled' " class="card m-2">-->
<!--                <div class="card-body" class="p-2 d-flex flex-row gap-2">-->
<!--                    <img src="{{item.productId.thumbnail}}" alt="" style="height:5rem; width: 5rem;">-->
<!--                    <div class="d-flex flex-column w-50">-->
<!--                        <h5 class="card-title">{{item.productId.title}}</h5>-->
<!--                        <span class="card-text">{{item.productId.description}}</span>-->
<!--                    </div>-->
<!--                    <div class="ms-auto d-flex flex-column justify-content-between">-->
<!--                        <span><i class="bi bi-currency-rupee"></i>{{item.productId.price}} x {{item.quantity}}</span>-->
<!--                        <button class="btn btn-warning btn-sm" *ngIf=" isLoggedIn  && item.canceled == false && orderDetail.status ==='completed' " [disabled]="loading" (click)="cancelItem(item.productId.id)">Cancel Item</button>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <button class="cancel btn btn-primary" [disabled]="loading"  *ngIf="isLoggedIn  &&  orderDetail && orderDetail.orderDetails.totalItems >= 1 && orderDetail.status === 'completed' " (click)="orderCancel(orderDetail._id)" >Cancel Order</button>-->
<!--    </div>-->
<!--    <div class="summery card" *ngIf="orderDetail && orderDetail.orderDetails && orderDetail.status!== 'canceled' && orderDetail.orderDetails.totalItems >= 1 " >-->
<!--       <div class="card-body">-->
<!--        <h3 class="text-center">Order Summery</h3>-->
<!--        <div class="orderDetails">-->
<!--            <div><span>TotalItems</span><span>{{orderDetail.orderDetails.totalItems}}</span></div>-->
<!--            <div><span>TotalPrice</span><span>{{orderDetail.orderDetails.totalPrice}}</span></div>-->
<!--        </div>-->
<!--        <div id="address-details">-->
<!--            <b>Ship to:</b>-->
<!--            <div class="shippingDetails">-->
<!--                <span>{{orderDetail.shippingDetails.name}}</span>-->
<!--                <span>{{orderDetail.shippingDetails.phone}}</span>-->
<!--                <span>{{orderDetail.shippingDetails.address.house}}</span>-->
<!--                <span>{{orderDetail.shippingDetails.address.street}}</span>-->
<!--                <span>{{orderDetail.shippingDetails.address.city}}</span>-->
<!--                <span>{{orderDetail.shippingDetails.address.state}} {{orderDetail.shippingDetails.address.pincode}}</span>-->
<!--            </div>-->
<!--        </div>-->
<!--       </div>-->
<!--    </div>-->
<!--</div>-->

