<div class="main">
    <form [formGroup]="form" class="m-auto mt-5 p-4 d-flex flex-column gap-3 border text-bg-light" (ngSubmit)="onSubmit()">
        <div class="input-box">
            <label for="password" class="form-label">Enter new Password</label>
            <input type="password" class="form-control" id="password" formControlName="password">
            <div *ngIf="password && password.touched" class="input-error">
                <span *ngIf="password.errors?.['required'] ">Enter password</span>
                <span *ngIf="password?.errors?.['minlength'] ">password length should be min 3 letter</span>
            </div>
        </div>
        <div class="input-box">
            <label for="confirmPassword" class="form-label">Confirm new Password</label>
            <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
            <div *ngIf="confirmPassword && confirmPassword.touched" class="input-error">
                <span *ngIf="confirmPassword.errors?.['required'] ">Enter confirmPassword</span>
                <span *ngIf="confirmPassword?.errors?.['minlength'] ">confirmPassword length should be min 3 letter</span>
            </div>
            <span class="input-error" *ngIf="form.errors?.['notMatch']">Both password not matched</span>
        </div>
        <button class="btn btn-primary" [disabled]="form.status==='INVALID' ">Update Password</button>
    </form>
</div>