<div class="py-5">
    <h1 class="text-center p-3">Best selling Products</h1>
<div class="container-fluid">
    <div class="row row-cols-2 row-cols-sm-4 row-cols-md-6 g-2 gy-2">
    <div class="col  p-2" *ngFor="let product of products">
            <div  class="card" [routerLink]="['/products',product.id]">
            <img class="card-img-top" style="height: 6rem;" [src]="getImage(product.thumbnail)" alt="{{product.title}}">
            <div class="card-body p-2 d-flex flex-column justify-content-between">
<!--                <h5 class="card-title" style="font-size: 12px; font-weight: 900;">{{product.brand}}</h5>-->
                <h6 class="card-title text-truncate small">{{product.title| titlecase}}</h6>
                <p class="card-text text-body-secondary overflow-y-hidden" style="height: 2.5rem; font-size: 14px;">{{product.description}}</p>
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <span><i class="bi bi-currency-rupee"></i>{{product.price}}</span>
                    <span class="float-end text-warning-emphasis" style="font-size: 12px;"><i
                            class="bi bi-star-fill"></i>{{product.rating}}</span>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
</div>
