import {Component, Input} from '@angular/core';
import {Product} from "../../../models/product.model";
import {Order} from "../../../models/order.model";
import {OrderService} from "../../../services/order.service";
import {ToastrService} from "ngx-toastr";
import {finalize} from "rxjs";
declare var Razorpay: any;

@Component({
  selector: 'app-order-photos',
  templateUrl: './order-photos.component.html',
  styleUrls: ['./order-photos.component.css']
})
export class OrderPhotosComponent {
  @Input() product!: Product;
  @Input() order!: Order;
  photoRequiredArray: any[] = [];
  selectedFiles: File[] = [];
  loading = false;
  constructor(
    private orderService: OrderService,
    private toastr : ToastrService
  ) {
  }

  options = {
    "key": "",
    "amount": "",
    "name": "Unique",
    "description": "Customized Gifts",
    "order_id":"",
    "handler": function (response: any){
      var event = new CustomEvent("payment.success",
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );
      window.dispatchEvent(event);
    }
    ,
    "prefill": {
      "name": "",
      "email": "",
      "contact": ""
    },
    "notes": {
      "address": ""
    },
    "theme": {
      "color": "#3399cc"
    }
  };

  ngOnInit(): void {
    this.photoRequiredArray = new Array(this.product?.noPhotosRequired);
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      this.selectedFiles.push(files[0]);
    }
  }

  uploadNextImage(index: number) {
    if (index < this.selectedFiles.length) {
      const formData = new FormData();
      formData.append('image', this.selectedFiles[index]);

      this.orderService.uploadOrderImages(this.order.id, formData).pipe(
        finalize(() => {
          if (index === this.selectedFiles.length - 1) {
            this.loading = false;
          }
        })
      ).subscribe(
        () => {
          this.uploadNextImage(index + 1); // Move to the next image
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.message);
        }
      );
    }
  }

  uploadImages() {
    this.loading = true;
    this.uploadNextImage(0);
    this.payment();
  }

  payment() {
    this.orderService.createOrder(this.order.id).subscribe(data => {
        this.options.key = data.secretId;
        this.options.order_id = data.razorpayOrderId;
        this.options.amount = data.applicationFee; //paise
        this.options.prefill.name = this.order?.shippingDetails?.name;
        this.options.prefill.email = this.order?.shippingDetails?.name;
        this.options.prefill.contact = this.order?.shippingDetails?.phone;
        var rzp1 = new Razorpay(this.options);
        rzp1.open();
        rzp1.on('payment.failed', function (response: any){
            // Todo - store this information in the server
            console.log(response);
            console.log(response.error.code);
            console.log(response.error.description);
            console.log(response.error.source);
            console.log(response.error.step);
            console.log(response.error.reason);
            console.log(response.error.metadata.order_id);
            console.log(response.error.metadata.payment_id);
          }
        );
      }
      ,
      err => {
        console.log(err.error.message);
      }
    );
  }

}
