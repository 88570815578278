import {Component, ElementRef, ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { states } from '../../state';
import { OrderService } from 'src/app/services/order.service';
import { ToastrService } from 'ngx-toastr';
import { LoggerService } from 'src/app/services/logger.service';
import { Cart } from 'src/app/models/cart.model';
import {ProductService} from "../../services/product.service";
import {Product} from "../../models/product.model";
import {OrderRequest} from "../../models/order-request";
import {Order} from "../../models/order.model";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent {
  customizedText: string[] = [];
  constructor(
    private router: Router,
    public loggerService: LoggerService,
    private Arouter: ActivatedRoute,
    private productService: ProductService,
    private orderService: OrderService,
    private toastr : ToastrService
  ) {
  }

  cartItems: any[] = []
  cartDetails!: Cart;
  states: string[] = states;
  isClassAdded: boolean = false;
  product!: Product;
  order!: Order;
  textRequiredArray: any[] = [];
  loading = false;
  orderPlaced = false;

  // isLoggedIn:boolean =false

  addClass(): void {
    this.isClassAdded = true;
  }

  ngOnInit(): void {
    // this.isLoggedIn= this.loggerService.isLoggedin;
    // let cart = localStorage.getItem('cart');
    // if (cart) {
    //   this.cartDetails = JSON.parse(cart);
    //    this.cartItems = this.cartDetails.cartItems;
    //   if (this.cartDetails && this.cartDetails.cartItems.length < 0) {
    //     this.router.navigate(['/cart']);
    //   }
    // } else {
    //   this.router.navigate(['/cart']);
    // }
    this.Arouter.params.subscribe((params) => {
      // this.loading = true;
      const id = params['id'];
      this.productService.getProductById(id).subscribe((res:any) => {
        this.product = res;
        this.textRequiredArray = new Array(this.product?.noCustomizedTextRequired);
        // this.loading = false;
      });
    });
  }

  form = new FormGroup({
    bname: new FormControl('', [Validators.minLength(3)]),
    email: new FormControl('', [Validators.minLength(8)]),
    password: new FormControl('', [Validators.minLength(6)]),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[6-9]{1}[0-9]{9}$'),
    ]),
    house: new FormControl('', [Validators.required, Validators.minLength(2)]),
    street: new FormControl('', [Validators.required, Validators.minLength(6)]),
    city: new FormControl('', [Validators.required, Validators.minLength(3)]),
    state: new FormControl('', [Validators.required, Validators.minLength(3)]),
    pincode: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern('^[0-9]*$')
    ]),
  });

  get bname() {
    return this.form.get('bname');
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get name() {
    return this.form.get('name');
  }

  get phone() {
    return this.form.get('phone');
  }

  get house() {
    return this.form.get('house');
  }

  get street() {
    return this.form.get('street');
  }

  get city() {
    return this.form.get('city');
  }

  get state() {
    return this.form.get('state');
  }

  get pincode() {
    return this.form.get('pincode');
  }

  // placeOrder() {
  //  if(this.form.errors){
  //   return
  //  }
  //  else{
  //    this.orderService.placeOrder(this.form.value,this.cartDetails).subscribe((data: any) => {
  //      if (data) {
  //        localStorage.removeItem('cart');
  //        this.cartService.getUserCart()
  //        this.toastr.success(data.msg)
  //        setTimeout(() => {
  //          this.router.navigate(['/order'])
  //        }, 1500);
  //      }
  //    });
  //  }
  // }

  onTextChange($event: any) {
    this.customizedText.push($event?.target?.value);
  }

  buildOrderCreatePayload(product: Product): OrderRequest {
    const shippingDetails = this.form.getRawValue();
    return {
      userId: "",
      productId: product.id,
      amount: product.price,
      shippingDetails: {
        name: shippingDetails.name || '',
        phone: shippingDetails.phone || '',
        houseNo: shippingDetails.house || '',
        street: shippingDetails.street || '',
        city: shippingDetails.city || '',
        state: shippingDetails.state || '',
        pincode: shippingDetails.pincode || ''
      },
      customizedText: this.customizedText
    }
  }

  onClickPlaceOrder(product: Product) {
    this.loading = true;
    const orderRequest = this.buildOrderCreatePayload(product);
    this.orderService.placeOrder(orderRequest).subscribe((order) => {
      this.order = order;
      this.loading = false;
      this.orderPlaced = true;
    }
      ,
      err => {
        this.loading = false
        this.toastr.error(err.error.message)
      })
  }
}
