import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductdetailComponent } from './components/products/productdetail/productdetail.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { authGuard } from './services/auth.guard';
import { OrdersComponent } from './components/orders/orders.component';
import { WrongurlComponent } from './components/wrongurl/wrongurl.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ForgotpasswordComponent } from './components/passwordforms/forgotpassword/forgotpassword.component';
import { UpdatepasswordComponent } from './components/passwordforms/updatepassword/updatepassword.component';
import { OrderDetailsComponent } from './components/order-details/orderDetails.component';
import { AboutComponent } from './components/about/about.component';
import { SearchproductComponent } from './components/products/searchproduct/searchproduct.component';
import { PaymentsuccessComponent } from './components/partials/paymentsuccess/paymentsuccess.component';
import { PaymentfailedComponent } from './components/partials/paymentfailed/paymentfailed.component';
import {PrivacyPolicyComponent} from "./components/privacy-policy/privacy-policy.component";
import {TermsConditionsComponent} from "./components/terms-conditions/terms-conditions.component";
import {ShippingPolicyComponent} from "./components/shipping-policy/shipping-policy.component";



const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'updatepassword/:emailToken', component: UpdatepasswordComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'products/:id', component: ProductdetailComponent },
  { path: 'products/search/:query', component: SearchproductComponent },
  { path: 'checkout/:id', component: CheckoutComponent },
  { path: 'order', canActivate: [authGuard],component: OrdersComponent },
  { path: 'order/:orderId', component: OrderDetailsComponent},
  { path: 'about', component: AboutComponent },
  { path: 'success', component: PaymentsuccessComponent },
  { path: 'failed', component: PaymentfailedComponent },
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms-of-condition', component:TermsConditionsComponent},
  {path: 'shipping-policy', component: ShippingPolicyComponent},
  { path: '**', component: WrongurlComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
