<div class="shipping-policy">
  <h2>Shipping Policy</h2>
  <p>Thank you for choosing Unique for your customized gift needs! We are thrilled to provide you with our exceptional products and services. Here's a brief overview of our shipping policy:</p>

  <ul>
    <li><strong>Processing Time:</strong> Orders are typically processed within 1-2 business days.</li>
    <li><strong>Shipping Time:</strong> Once processed, orders are shipped within 3-5 business days.</li>
    <li><strong>Delivery Time:</strong> Expect your order to arrive within 7-10 business days from the date of purchase.</li>
    <li><strong>Online Payment Only:</strong> Please note that we only accept online payments at the time of purchase. We do not offer cash on delivery options.</li>
    <li><strong>Tracking:</strong> You will receive a tracking number via email once your order has been shipped, allowing you to monitor its progress until it reaches your doorstep.</li>
    <li><strong>Shipping Fees:</strong> Shipping fees are calculated at checkout based on the weight and destination of your order.</li>
  </ul>

  <p>Should you have any further questions or concerns about our shipping policy, please don't hesitate to contact our customer service team. Thank you for shopping with us!</p>
</div>
