<div class="main">
    <form [formGroup]="form" class="m-auto mt-5 p-4 d-flex flex-column gap-3 border text-bg-light" (ngSubmit)="onSubmit()">
        <div class="input-box">
            <label for="email" class="form-label">Enter email</label>
            <input type="email" class="form-control" id="email" formControlName="email">
            <div *ngIf="email && email.touched" class="input-error">
                <span *ngIf="email.errors?.['required'] ">Enter email</span>
                <span *ngIf="email?.errors?.['minlength'] ">email length should be min 3 letter</span>
            </div>
        </div>
        <button class="btn btn-primary" [disabled]="form.status === 'INVALID' || loading || linkSent">
            Send Link
            <span *ngIf="loading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        </button>
    </form>
</div>