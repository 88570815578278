import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  get isLoggedin() {
    return localStorage.getItem('hasLogin') == "true";
  }
}

