import {Component, ElementRef} from '@angular/core';
import * as THREE from 'three';
import {CSS2DObject, CSS2DRenderer} from "three/examples/jsm/renderers/CSS2DRenderer";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  scene!: THREE.Scene;
  camera!: THREE.PerspectiveCamera;
  renderer!: THREE.WebGLRenderer;
  cssRenderer!: CSS2DRenderer;
  particles!: THREE.Points;

  constructor(private elRef: ElementRef) { }

  ngOnInit(): void {
    this.initScene();
    this.createParticles();
    this.addText();
    this.animate();
    this.onWindowResize();
    window.addEventListener('resize', () => this.onWindowResize(), false);
  }

  private initScene() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / (window.innerHeight), 1, 1000);
    this.camera.position.z = 400;
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setClearColor("#f1dddd");
    // CSS2D Renderer
    this.cssRenderer = new CSS2DRenderer();
    this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
    this.cssRenderer.domElement.style.position = 'absolute';
    this.cssRenderer.domElement.style.top = '0';
    this.elRef.nativeElement.appendChild(this.cssRenderer.domElement);
    this.elRef.nativeElement.appendChild(this.renderer.domElement);
  }
  private createParticles() {
    const particleCount = 1000;
    const positions = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
      positions[i * 3] = Math.random() * 800 - 400;
      positions[i * 3 + 1] = Math.random() * 800 - 400;
      positions[i * 3 + 2] = Math.random() * 800 - 400;
    }

    const particles = new THREE.BufferGeometry();
    particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));

    const particleMaterial = new THREE.PointsMaterial({
      color: 0xFF0000,
      size: 2,
      map: new THREE.TextureLoader().load('../../../assets/heartImage.jpeg')
    });

    this.particles = new THREE.Points(particles, particleMaterial);
    this.scene.add(this.particles);
  }

  private addText() {
    const div = document.createElement('div');
    div.className = 'label';
    div.textContent = 'Our Love, Our Way: Custom Gifts as Unique as Our Story';
    div.style.width = "100vw";
    div.style.color = '#333333';
    div.style.fontSize = '18px';
    div.style.fontWeight = 'bold';
    div.style.textAlign = 'center';
    const label = new CSS2DObject(div);
    label.position.set(0, 0, -200);
    this.scene.add(label);
  }

  private animate() {
    requestAnimationFrame(() => this.animate());
    this.particles.rotation.x += 0.005;
    this.particles.rotation.y += 0.005;
    this.renderer.render(this.scene, this.camera);
    this.cssRenderer.render(this.scene, this.camera);
  }

  private onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
  }



}
