<!-- <div *ngIf="loading " class="loader w-100  d-flex justify-content-center position-absolute " style="height: 100vh;">
  <div class="spinner-border mx-auto" role="status" style="--bs-spinner-animation-speed: 0.45s;">
    <span class="visually-hidden">Loading...</span>
  </div>
</div> -->

<div class="p-4 d-flex flex-column gap-2 align-items-center text-center fs-2" *ngIf="!product && !loading">
  <span>Product not found</span>
  <button class="btn btn-primary" routerLink="/products" >Go Back</button>
</div>

<div  class="container-fluid py-4">
  <div
    class="p-2 d-flex flex-column flex-md-row gap-5 justify-content-center align-items-center"
    *ngIf="product">
    <!-- image of product -->
    <div style=" max-width: 30rem ;">
      <img
        src="{{ image || product.images[0] }}"
        alt="{{ product.title }}"
        style="width: 100%; height: 20rem; object-fit: contain;"
        />

        <div class="mt-2 m-auto d-flex flex-row gap-1 border" style="height: 6rem;width: 20rem; overflow-x: auto;">
          <img src="{{img}}" alt="" *ngFor="let img of product.images" style="height: 100%; width: auto;object-fit: cover; border: 1px solid lightslategrey;" (click)="changeImg(img)">
        </div>
    </div>

    <!-- product Details -->
    <div style="max-width: 30rem; align-self: flex-start;">
<!--      <span style="font-size: 12px; color: rgb(76, 76, 245)"-->
<!--        >Brand : {{ product.brand }}</span>-->
      <h6 class="fs-3">{{ product.title| titlecase }}</h6>
      <p class="text-secondary">{{ product.description }}</p>
      <span><i class="bi bi-star-fill text-warning"></i> {{ product.rating }}
      </span>
      <br />
      <br />
      <div class="position-relative">
        <span class="fs-4"><i class="bi bi-currency-rupee"></i>{{ product.price }}</span>
      <span
        style="
          padding-left: 5px;
          text-decoration: line-through;
          font-size: 10px;
          position: absolute;
          bottom: 0.9rem;
          color: red;
          ">
          {{ product.discountPercentage }}% <i class="bi bi-arrow-down"></i>
       </span>
      </div>
       <br>
       <div class="d-flex flex-row gap-2  ">
        <button class="btn btn-primary btn-sm" [disabled]="true" (click)="onClickBuyNow(product.id)">Buy Now<span *ngIf="loading" class="spinner-border spinner-border-sm" aria-hidden="true"></span> <i *ngIf="!loading"
                                                                                                                                                                                                         class="bi bi-cart"></i></button>
<!--        <button class="btn btn-sm btn-warning" [disabled]="loading"  (click)="wishlistProduct(product.id)">Add to wishlist <i class="bi bi-heart p-1"></i></button>-->
       </div>
<!--       <h6 *ngIf="product.stock === 0" class="p-2 text-danger fs-6">This item is currently out of stock</h6>-->
    </div>
  </div>
</div>

