import { ChangeDetectorRef, Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/models/product.model';
// import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { WishlistService } from 'src/app/services/wishlist.service';

@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.css'],
})
export class ProductdetailComponent {
  constructor(
    private Arouter: ActivatedRoute,
    private productService: ProductService,
    private wishlistService: WishlistService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}
  product: Product= {
    id: "1",
    title: "customize Chocolate",
    description: "Discover personalized joy with our custom Dairy Milk treats. Tailored to perfection, each bite is a unique delight. Perfect for any occasion or as a heartfelt gift, our bespoke chocolates elevate the sweetness of every moment. Indulge in your own story with our customized Dairy Milk collection.",
    discountPercentage: 10,
    price: 399,
    rating: 4,
    noPhotosRequired: 0,
    photoLabel: [],
    noCustomizedTextRequired: 0,
    textLabel: [],
    thumbnail: "../../../assets/chocolate.jpg",
    images: ["../../../assets/chocolate.jpg"],
  };
  image: string = '';
  loading: boolean = false;
  token: string = '';

  changeImg(img: string) {
    this.image = img;
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('token') || '';
    this.Arouter.params.subscribe((params) => {
      this.image=''
      // this.loading = true;
      const id = params['id'];
      // this.productService.getProductById(id).subscribe((res:any) => {
      //   this.product = res;
      //   // this.loading = false;
      // });
    });
    this.cdr.detectChanges();
  }

  onClickBuyNow(id: any) {
    this.router.navigateByUrl(`/checkout/${id}`);
  }

  getImage(base64String: any) {
    return `data:image/jpeg;base64,${base64String}`;
  }
}
