import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environment/environment';

import {Observable } from 'rxjs';
import {OrderRequest} from "../models/order-request";


@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}

  url: string = environment.API_URL;
  orderData: any[] = [];



  getUserOrder(): Observable<any> {
    return this.http.get(`${this.url}/order`);
  }

  getOrderDetails(orderId: string): Observable<any> {
    return this.http.get(`${this.url}/order/${orderId}`);
  }
  trackOrder(orderId: string, email: string): Observable<any> {
    return this.http.get(`${this.url}/track/${orderId}/${email}`);
  }

  placeOrder(order: OrderRequest): Observable<any> {
    return this.http.post(`${this.url}/api/order`, order);
  }

  uploadOrderImages(orderId: string, data: any): Observable<any> {
    return this.http.put(`${this.url}/api/order/upload-photo/${orderId}`, data);
  }

  cancelItemFromOrder(orderId: string, productId: string): Observable<any> {
    return this.http.put(`${this.url}/order/${orderId}`, { productId });
  }

  cancelOrder(orderId: string): Observable<any> {
    return this.http.put(`${this.url}/order/cancel/${orderId}`, {});
  }

  createOrder(orderId: string): Observable<any> {
    return this.http.post(`${this.url}/api/payment/${orderId}`, null);
  }
}
