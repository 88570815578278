import { Component } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent {
  constructor(private productService: ProductService) {}
  products: Product[] = [{
    id: "1",
    title: "customize Chocolate",
    description: "Discover personalized joy with our custom Dairy Milk treats. Tailored to perfection, each bite is a unique delight. Perfect for any occasion or as a heartfelt gift, our bespoke chocolates elevate the sweetness of every moment. Indulge in your own story with our customized Dairy Milk collection.",
    discountPercentage:399,
    price: 500,
    rating: 4,
    noPhotosRequired: 0,
    photoLabel: [],
    noCustomizedTextRequired: 0,
    textLabel: [],
    thumbnail: "../../../assets/chocolate.jpg",
    images: ["../../../assets/chocolate.jpg"],
  }];
  loading: boolean = false;

  ngOnInit() {
    this.loading = false;
    // this.productService.getAllProducts().subscribe((res) => {
    //   this.products = res;
    //   this.loading = false;
    // });
  }

  getImage(base64String: any) {
    return `data:image/jpeg;base64,${base64String}`;
  }
}
