import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from '../environment/environment';


@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}
  url: string = environment.API_URL;

  getAllProducts(): Observable<any> {
    return this.http.get(`${this.url}/api/products`);
  }

  getLimitedProducts(): Observable<any> {
    return this.http.get(`${this.url}/api/products`);
  }
  getProductById(id: string): Observable<any> {
    return this.http.get(`${this.url}/api/products/${id}`);
  }
  getSearchProducts(title: string): Observable<any> {
    return this.http.get(`${this.url}/products/search?q=${title}`);
  }

  createOrder(): Observable<any> {
    return this.http.post("http://localhost:8080/api/payment/65c359928a5c5c5e249a9127", null);
  }
}
