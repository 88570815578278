import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  url: string = environment.API_URL;
  count: number = 0;

  login(data: any) {
    return this.http.post(`${this.url}/auth/login`, data).subscribe((res: any) => {
      localStorage.setItem('token', res.token);
      localStorage.setItem('hasLogin', "true");
      this.router.navigate(['/']);
      this.toastr.success(res.msg);
    });
  }
  signup(data: any) {
    return this.http
      .post(`${this.url}/auth/register`, data)
      .subscribe((res: any) => {
        localStorage.setItem('token', res.token);
        this.router.navigate(['/']);
        localStorage.setItem('hasLogin', "true");
        this.toastr.success(res.msg);
      });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  forgotPassword(email: any) {
    return this.http.post(`${this.url}/forgetpassword`, { email: email });
  }

  updatePassword(form: any, emailToken: string) {
    return this.http
      .put(`${this.url}/updatepassword/${emailToken}`, form)
      .subscribe((res: any) => {
        this.toastr.success(res.msg);
        this.router.navigate(['/login']);
      });
  }
}
