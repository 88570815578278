<div class="footer">
  <div class="footer-content">
    <div class="links">
      <a routerLink="/privacy-policy">Privacy Policy</a>
      <a routerLink="/terms-of-condition">Terms of Condition</a>
      <a routerLink="/shipping-policy">Shipping policy</a>
    </div>
    <div class="disclaimer">
      <p>No refunds are provided for customized gifts.</p>
    </div>
    <div class="address">
      <p>Unique</p>
      <p>2nd chruch street,golden singar near</p>
      <p>Karaikudi, Tamilnadu, 630001</p>
      <p>India</p>
      <p>Email: uniquekkdi@gmail.com</p>
    </div>
    <div class="copyright">
      &copy; 2024 Unique. All rights reserved.
    </div>
  </div>
</div>
