<div *ngIf="!orderPlaced" class=" p-sm-3 d-flex flex-column flex-md-row justify-content-around gap-5">
    <div id="contact">
        <div class="login d-flex flex-column gap-1" *ngIf="!loggerService.isLoggedin">
            <a routerLink="/login">Already a customer ?</a>
            <span class="divider">
                <hr><span>or</span>
                <hr>
            </span>
<!--            <h4 class="text-decoration-underline">Continue as Guest</h4>-->
<!--            <span>(Tracking id will be sent to your email)</span>-->
            <br>
        </div>
        <h3 *ngIf="!loggerService.isLoggedin">Contact Information</h3>
        <form class="d-flex flex-column gap-2" [formGroup]="form">
            <div *ngIf="!loggerService.isLoggedin">
                <label for="bname" class="form-label">Name</label>
                <input type="name" class="form-control" id="bname" formControlName="bname">
                <div *ngIf="bname && bname.touched" class="input-error">
                    <span *ngIf="bname.errors?.['required'] ">Enter Name</span>
                    <span *ngIf="bname?.errors?.['minlength'] ">bname length should be min 3 letter</span>
                </div>
            </div>
            <div *ngIf="!loggerService.isLoggedin">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email" formControlName="email">
                <div *ngIf="email && email.touched" class="input-error">
                    <span *ngIf="email.errors?.['required'] ">Enter email</span>
                    <span *ngIf="email?.errors?.['minlength'] ">email length should be min 3 letter</span>
                </div>
            </div>
            <div *ngIf="!loggerService.isLoggedin">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" formControlName="password">
                <div *ngIf="password && password.touched" class="input-error">
                    <span *ngIf="password.errors?.['required'] ">Enter password</span>
                    <span *ngIf="password?.errors?.['minlength'] ">password length should be min 6 letter</span>
                </div>
            </div>
            <div class="row">
                <h3 class="pt-2">Shipping address</h3>
                <div class="col-sm-6">
                    <label for="name" class="form-label">name</label>
                    <input type="name" class="form-control" id="name" formControlName="name">
                    <div *ngIf="name && name.touched" class="input-error">
                        <span *ngIf="name.errors?.['required'] ">Enter name</span>
                        <span *ngIf="name?.errors?.['minlength'] ">name length should be min 3 letter</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="phone" class="form-label">phone</label>
                    <input type="phone" class="form-control" maxlength="10" id="phone" formControlName="phone">
                    <div *ngIf="phone && phone.touched" class="input-error">
                        <span *ngIf="phone.errors?.['required'] ">Enter phone number</span>
                        <span *ngIf="phone?.errors?.['minlength'] || phone?.errors?.['pattern'] ">Enter valid
                            phone
                            number</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="house" class="form-label">House</label>
                    <input type="text" class="form-control" id="house" placeholder="1234 Main St" formControlName="house">
                    <div *ngIf="house && house.touched" class="input-error">
                        <span *ngIf="house.errors?.['required'] ">Enter house number</span>
                        <span *ngIf="house?.errors?.['minlength'] ">Enter valid house number</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="address" class="form-label">Address</label>
                    <input type="text" class="form-control" id="address" placeholder="Near new building , main road"
                        formControlName="street">
                    <div *ngIf="street && street.touched" class="input-error">
                        <span *ngIf="street.errors?.['required'] ">Enter Street</span>
                        <span *ngIf="street?.errors?.['minlength'] ">Enter minimum 6 character</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="city" class="form-label">City</label>
                    <input type="text" class="form-control" id="city" formControlName="city">
                    <div *ngIf="city && city.touched" class="input-error">
                        <span *ngIf="city.errors?.['required'] ">Enter city</span>
                        <span *ngIf="city?.errors?.['minlength'] ">Enter valid city</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="state" class="form-label">State</label>
                    <select id="state" class="form-select" formControlName="state">
                        <option value=""></option>
                        <option *ngFor="let state of states" value="{{state}}">{{state}}</option>
                    </select>
                    <div *ngIf="state && state.touched" class="input-error">
                        <span *ngIf="state.errors?.['required'] ">Enter state</span>
                        <span *ngIf="state?.errors?.['minlength'] ">Enter valid state</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="pincode" class="form-label">Pincode</label>
                    <input type="text" class="form-control" id="pincode" maxlength="6" formControlName="pincode">
                    <div *ngIf="pincode && pincode.touched" class="input-error">
                        <span *ngIf="pincode.errors?.['required'] ">Enter pincode</span>
                        <span *ngIf="pincode?.errors?.['minlength'] || pincode?.errors?.['pattern'] ">Enter
                            valid pincode
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="my-sm-5" style="min-width: 20rem; max-width: 28rem; height: max-content; padding: 5px; margin: 5px;  border: 1px solid rgb(206, 208, 209); text-align: center;">
        <h2>Cart Summery</h2>
        <div class="summery">
            <span>Subtotal</span>
            <span>{{product.price}}</span>
        </div>

        <div class="summery">
            <span>Shipping charges</span>
            <span>0</span>
        </div>
        <hr>
        <div class="summery">
            <span>Total</span>
            <span>{{product.price}}</span>
        </div>
    </div>
</div>
<div *ngIf="product?.noCustomizedTextRequired && !orderPlaced" class="col-6">
  <h2>Customized Texts</h2>
  <div class="m-2" *ngFor="let image of textRequiredArray; let i = index">
    <label class="m-2">{{product.textLabel[i]}}</label>
    <input type="text" class="form-control" (change)="onTextChange($event)">
  </div>
</div>
<button *ngIf="!orderPlaced" [disabled]="loading" class="m-2 btn btn-primary btn-sm" (click)="onClickPlaceOrder(product)">Place Order<span *ngIf="loading" class="spinner-border spinner-border-sm" aria-hidden="true"></span></button>
<app-order-photos *ngIf="orderPlaced" [order]="order" [product]="product"></app-order-photos>

