<div class="container p-1 pt-5">
    <div class="card">
        <div class="card-body">
            <div class="position-absolute ">
            <i class="bi bi-check2"></i>
            </div>
            <h2>Thank you!</h2>
            <h6>Your payment is successful</h6>
            <button *ngIf="loggedIn" class="btn btn-primary w-75 btn-sm mt-3" routerLink="/order">My Orders</button>
            <div *ngIf="!loggedIn && orderId">
                <span>Your OrderId is </span>
                <span>{{orderId}}</span>
                <button class="btn btn-primary w-75 btn-sm mt-3" routerLink="/track">Track Here</button>
            </div>

        </div>
    </div>
</div>