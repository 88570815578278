import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'bestselling',
  templateUrl: './bestSelling.component.html',
  styleUrls: ['./bestSelling.component.css'],
})
export class BestsellingComponent implements OnInit{
  constructor(private router:Router,private productService: ProductService) {}
  products: Product[] = [];

  ngOnInit() {
    this.productService.getLimitedProducts().subscribe((res) => {
      this.products = res;
    });
  }

  productDetails(title:string){
      this.router.navigateByUrl(`/product/${title}`)
  }

  getImage(base64String: any) {
    return `data:image/jpeg;base64,${base64String}`;
  }
}
