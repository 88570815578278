export const states = [
  "AndraPradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "HimachalPradesh",
  "JammuKashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "MadhyaPradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "TamilNadu",
  "Telangana",
  "Tripura",
  "UttarPradesh",
  "Uttarakhand",
  "WestBengal",
];