<div class="container">
    <div class="card">
        <div class="card-body">
            <div class="position-absolute ">
            <i class="bi bi-exclamation"></i>
            </div>
            <h2>Sorry...</h2>
            <h6>Your payment is Failed</h6>
            <button class="btn btn-danger w-75 btn-sm mt-3" routerLink="/checkout">Retry</button>
        </div>
    </div>
</div>